import React from "react";
import { Text, Flex, Grid, Box } from "@mantine/core";
import { FaCheckCircle } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";

export const ConfirmAid = ({ data, GlobalCard }) => {
  const getAmbulanceData = data?.[0];
  const parsedAmbulanceData = getAmbulanceData?.descripcionServicio
    ? JSON.parse(getAmbulanceData?.descripcionServicio)
    : {};

  const ambulanceData = [
    {
      checked: parsedAmbulanceData?.medicalizedCareTransfer,
      name: "Traslado asistencial básico",
    },
    {
      checked: parsedAmbulanceData?.medicalizedCareTransfer,
      name: "Traslado asistencial medicalizado",
    },
    { checked: parsedAmbulanceData?.withCompanion, name: "Con acompañante" },
    {
      checked: parsedAmbulanceData?.ambulanceAvailability,
      name: "Disponibilidad de ambulancia",
    },
  ];
  return (
    <Box>
      <Text color="#004236" size="xl" fw={500}>
        Servicio Ambulancia
      </Text>
      <GlobalCard>
        <Grid>
          <Grid.Col span={12}>
            <Flex
              justify="center"
              gap={12}
              align="center"
              sx={(theme) => ({
                [theme.fn.smallerThan("md")]: {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              })}
            >
              {ambulanceData?.map((item) => (
                <Flex key={item.name} align="center" gap={8}>
                  {item.checked === "true" ? (
                    <FaCheckCircle color="#004236" size="1rem" />
                  ) : (
                    <FaPlus
                      color="#c00"
                      size="1rem"
                      style={{
                        transform: "rotate(45deg)",
                      }}
                    />
                  )}
                  <Text fw={500} color="#004236">
                    {item.name}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Grid.Col>
        </Grid>
      </GlobalCard>
    </Box>
  );
};
