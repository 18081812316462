import {
  Grid,
  TextInput,
  NumberInput,
  ScrollArea,
  Flex,
  FileInput,
  Checkbox,
  Text,
} from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { FaUpload } from "react-icons/fa";
import { startCase, camelCase } from "lodash";
import Label from "./label.component";

const ReportedExpenses = ({
  reportedExpensesList,
  handleReportedExpensesFileChange,
  handleClickListOfProviders,
  handleInputChange,
  providerInputs,
  handleChangeProviderReported,
  openProviders,
  data,
  setData,
  open,
  isEdit,
  GlobalCard,
  classes,
  utils,
}) => {
  const ref = useClickOutside(() =>
    setData((prev) => ({ ...prev, listOfProviders: [] }))
  );

  const validateExpensesData =
    data?.travelLiquidatorData?.TipoProceso === 4
      ? data?.travelExpenses
      : data?.travelExpensesType;

  const searchName = (id) => {
    return validateExpensesData?.find((item) => item?.id === Number(id))?.label;
  };

  return reportedExpensesList?.map((expense, index) => (
    <GlobalCard key={index}>
      <Grid columns={5} align="center">
        <Grid.Col xs={5} md={1}>
          <TextInput
            defaultValue={
              expense?.tipoGasto
                ? expense.tipoGasto
                : searchName(expense?.GastoId) || expense?.Nombre
            }
            onChange={(e) =>
              handleInputChange(index, "tipoGasto", e.target.value)
            }
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Gasto reportado"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col xs={5} md={1}>
          <NumberInput
            onChange={(e) => handleInputChange(index, "Valor", e)}
            defaultValue={
              expense?.tipoGasto ? expense.valorFinal : expense?.Valor
            }
            parser={utils.parserNumberInput}
            formatter={utils.formatterNumberInput}
            hideControls={true}
            min={0}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Valor final del Proveedor"
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col xs={5} md={1}>
          <TextInput
            value={providerInputs?.[index]}
            onChange={(event) => handleChangeProviderReported(event, index)}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="NIT/Cédula Proveedor"
            withAsterisk
          />
          {providerInputs?.[index]?.length > 2 && openProviders[index] && (
            <ScrollArea
              ref={ref}
              h={data?.listOfProviders?.length > 2 ? 200 : "auto"}
              className={classes.scrollAreaLegalization}
            >
              {data?.listOfProviders?.map((item, indexProv) => (
                <Flex
                  direction="column"
                  key={indexProv}
                  sx={{
                    cursor: "pointer",
                    ":hover": {
                      background: "#F5F5F5",
                    },
                    borderBottom:
                      indexProv === data.listOfProviders.length - 1
                        ? "none"
                        : "1px solid #EEEEEE",
                  }}
                  data-testid="list-button-reported"
                  onClick={() => handleClickListOfProviders(index, item)}
                >
                  <Text size="sm" fw={700} color="#004236">
                    {startCase(camelCase(item.label))}
                  </Text>
                  <Text size="sm">{item.value.split("/")[1]}</Text>
                </Flex>
              ))}
            </ScrollArea>
          )}
        </Grid.Col>
        <Grid.Col xs={5} md={1}>
          <TextInput
            value={expense?.Proveedor}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Nombre/Proveedor"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col xs={5} md={1}>
          <FileInput
            onChange={(e) => handleReportedExpensesFileChange(e, index)}
            label={
              <Label
                title="Adjunte factura"
                description="Adjuntar un archivo con capacidad máxima de 200 MB."
                withAsterisk
              />
            }
            sx={{
              button: {
                overflow: "hidden",
                span: {
                  color: "#000",
                },
              },
            }}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            placeholder={isEdit ? String(expense?.NombFactura) || "" : ""}
            icon={<FaUpload size="1rem" />}
          />
        </Grid.Col>
        <Flex
          align="center"
          gap={12}
          mx="auto"
          mt={12}
          sx={{ cursor: "pointer" }}
        >
          {reportedExpensesList?.[index]?.EsNovedad && (
            <Text color="#004236" underline>
              *Con Novedades
            </Text>
          )}
          <Checkbox
            label="Gasto no utilizado"
            data-testid="checkBox-reportedExpenses"
            onChange={(e) =>
              handleInputChange(
                index,
                "GastoUtilizado",
                !e.currentTarget.checked
              )
            }
            className={classes.checkBox}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
          />
          <Text
            color="#004236"
            underline
            sx={{ cursor: "pointer" }}
            onClick={open}
          >
            Ingresar Proveedor
          </Text>
        </Flex>
      </Grid>
    </GlobalCard>
  ));
};

export default ReportedExpenses;
