import { Grid, TextInput, Checkbox, Flex, Box, Text, NumberInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import Label from "./label.component";
import IconCard from "./iconCard.component";

const ModifyDates = ({
  dates,
  allCosts,
  handleModifyInitialDate,
  handleModifyEndDate,
  handleCostChange,
  handleChangeUsedTrips,
  handleChangeTaxisValue,
  usedTrips,
  data,
  form,
  utils,
  validateDates,
  validateThirdUserOrIsAid,
  GlobalCard,
  classes,
  es,
}) => {
  const isMultiTravel = data?.travelLiquidatorData?.Multiviaje;

  const liquidatorData = data?.travelLiquidatorData?.ViajeTrayecto;

  const hasTaxis = allCosts?.[0]?.some(
    (cost) => cost?.alias === "VLRTAXI"
  );
  
  const validateTravelData = isMultiTravel
    ? liquidatorData
    : [liquidatorData?.[0]];

  return validateTravelData?.map((trip, index) => (
    <GlobalCard key={index}>
      <Grid align="center">
        <Grid.Col xs={12} md={3}>
          <TextInput
            value={trip?.nombreCiudadOrigen}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Ciudad de origen"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col xs={12} md={3}>
          <TextInput
            value={trip?.nombreCiudadDestino}
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
            label="Ciudad de destino"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col xs={12} md={3}>
          <DatePickerInput
            value={dates?.[index]?.[0] ? new Date(dates?.[index]?.[0]) : null}
            data-testid="datePickerInput-initial"
            onChange={(value) => handleModifyInitialDate(value, index)}
            locale={es}
            label="Modificar fecha inicial"
            classNames={{
              input: classes?.input,
              label: classes?.labelSecondary,
              day: classes?.calendar,
            }}
            valueFormat="DD/MMM/YY"
          />
        </Grid.Col>
        <Grid.Col xs={12} md={3}>
          <DatePickerInput
            value={dates?.[index]?.[1] ? new Date(dates?.[index]?.[1]) : null}
            data-testid="datePickerInput-end"
            onChange={(value) => handleModifyEndDate(value, index)}
            locale={es}
            label={
              <Label
                title="Modificar fecha final"
                description="Para activar la modificación de la fecha final, por favor, cambie primero la fecha inicial."
              />
            }
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
              day: classes.calendar,
            }}
            minDate={dates?.[index]?.[0] ? new Date(dates?.[index]?.[0]) : null}
            valueFormat="DD/MMM/YY"
            disabled={validateDates?.[index] === false}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex align="center" justify="space-between">
            {validateThirdUserOrIsAid && (
              <Box>
                <Text weight="bold" color="#004236" size="sm" mt={12}>
                  Viáticos
                </Text>
                <Text fz={12} color="#868e96">
                  Para agregar o quitar Viáticos, dar clic en los iconos.
                </Text>
                <Flex gap={8} mt={8}>
                  <IconCard
                    index={index}
                    data={allCosts?.[index]}
                    handleCostChange={handleCostChange}
                  />
                </Flex>
              </Box>
            )}
            {isMultiTravel && (
              <Checkbox
                label="Trayecto utilizado"
                data-testid="checkBox-dates"
                checked={usedTrips?.[index]}
                onChange={(e) => handleChangeUsedTrips(e, index)}
                className={classes.checkBox}
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
              />
            )}
          </Flex>
        </Grid.Col>
        {hasTaxis && (
            <NumberInput
              value={allCosts?.[index]?.find(item => item?.alias === "VLRTAXI")?.Valor}
              onChange={(e) => handleChangeTaxisValue(e, index)}
              parser={utils.parserNumberInput}
              formatter={utils.formatterNumberInput}
              min={0}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              hideControls
              label="Transporte terminales trayecto"
              description="Ingrese si tuvo un valor diferente"
            />
        )}
      </Grid>
    </GlobalCard>
  ));
};

export default ModifyDates;
