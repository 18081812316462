import {
  Text,
  Grid,
  Select,
  NumberInput,
  ScrollArea,
  FileInput,
  Flex,
  Button,
  ActionIcon,
  TextInput,
  Switch,
} from "@mantine/core";
import { FaUpload } from "react-icons/fa";
import { FaTrashCan } from "react-icons/fa6";
import { LuChevronDown } from "react-icons/lu";
import { useClickOutside } from "@mantine/hooks";
import { startCase, camelCase } from "lodash";

import Label from "./label.component";

const AddNewExpenses = ({
  form,
  data,
  setData,
  handleInputProviderChange,
  handleNewExpensesFileChange,
  handleAddExpense,
  newExpenses,
  handleDeleteExpense,
  open,
  isAid,
  isInternational,
  classes,
  GlobalCard,
  utils,
}) => {
  const ref = useClickOutside(() =>
    setData((prev) => ({ ...prev, listOfProviders: [] }))
  );

  const fields = [
    "newExpense",
    "newExpenseValue",
    "providerNameNewExpense",
    "idSupplierNewExpense",
    "billNewExpense",
  ];
  const areFieldsValid = fields?.every((field) => form?.isValid(field));

  return (
    <>
      <Text color="#004236" size="xl" fw={500}>
        Adicionar nuevos gastos
      </Text>
      <GlobalCard>
        <Grid align="center">
          <Grid.Col xs={12} md={isInternational ? 5 : 6}>
            <Select
              {...form.getInputProps("newExpense")}
              rightSection={<LuChevronDown size="1rem" />}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
                dropdown: classes.dropdown,
                rightSection: classes.rightSection,
                item: classes.item,
              }}
              label="Nuevo gasto"
              data={isAid ? data?.travelExpenses : data?.travelExpensesType}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col xs={12} md={isInternational ? 5 : 6}>
            <NumberInput
              {...form.getInputProps("newExpenseValue")}
              parser={utils.parserNumberInput}
              formatter={utils.formatterNumberInput}
              min={0}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              hideControls
              label="Valor"
              disabled={form.values.newExpense.length === 0}
              withAsterisk
            />
          </Grid.Col>
          {isInternational && (
            <Grid.Col xs={12} md={2}>
              <Switch
                checked={form.values.isDollar}
                data-testid="switch-newExpenses"
                onChange={(event) =>
                  form.setFieldValue("isDollar", event.currentTarget.checked)
                }
                label="USD"
                labelPosition="left"
                sx={{
                  ".mantine-Switch-label": {
                    color: "#004236",
                    fontWeight: 500,
                  },
                  "input:checked+.mantine-Switch-track": {
                    backgroundColor: "#cbd300",
                    borderColor: "#cbd300",
                  },
                  ".mantine-Switch-track": {
                    maxWidth: "2.8rem",
                  },
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col xs={12} md={4}>
            <TextInput
              {...form.getInputProps("providerNameNewExpense")}
              label="Proveedor"
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={4}>
            <TextInput
              {...form.getInputProps("idSupplierNewExpense")}
              onChange={handleInputProviderChange}
              label="NIT/Cédula del proveedor"
              placeholder="Ingrese el NIT o cédula para buscar el proveedor, si existe."
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              disabled={
                form.values.newExpense.length === 0 ||
                form.values.newExpenseValue.length === 0
              }
              withAsterisk
            />
            {form.values.idSupplierNewExpense.length > 2 && (
              <ScrollArea
                h={data?.listOfProviders?.length > 2 ? 200 : "auto"}
                className={
                  !data?.listOfProviders?.length
                    ? null
                    : classes.scrollAreaLegalization
                }
                ref={ref}
              >
                {data?.listOfProviders?.map((item, index) => (
                  <Flex
                    direction="column"
                    key={index}
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        background: "#F5F5F5",
                      },
                      borderBottom:
                        index === data.listOfProviders.length - 1
                          ? "none"
                          : "1px solid #EEEEEE",
                    }}
                    data-testid="list-button"
                    onClick={() => {
                      form.setFieldValue(
                        "idSupplierNewExpense",
                        item.value.split("/")[1]
                      );
                      form.setFieldValue("providerNameNewExpense", item.label);
                      setData((prev) => ({ ...prev, listOfProviders: [] }));
                    }}
                  >
                    <Text size="sm" fw={700} color="#004236">
                      {startCase(camelCase(item.label))}
                    </Text>
                    <Text size="sm">{item.value.split("/")[1]}</Text>
                  </Flex>
                ))}
              </ScrollArea>
            )}
          </Grid.Col>
          <Grid.Col xs={12} md={4}>
            <FileInput
              {...form.getInputProps("billNewExpense")}
              onChange={handleNewExpensesFileChange}
              label={
                <Label
                  title="Adjunte factura"
                  description="Adjuntar un archivo con capacidad máxima de 200 MB."
                  withAsterisk
                />
              }
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              icon={<FaUpload size="1rem" />}
            />
          </Grid.Col>
          <Grid.Col xs={12} md="content">
            <Text
              color="#004236"
              underline
              sx={{ cursor: "pointer" }}
              onClick={open}
            >
              Ingresar Proveedor
            </Text>
          </Grid.Col>

          <Grid.Col xs={12} md="auto">
            <Flex align="flex-end" direction="column" gap={8}>
              <Text color="#004236">
                {`Por favor, recuerde que para agregar los gastos debe dar clic
                  en `}
                <Text span fw={500}>
                  Agregar
                </Text>
                .
              </Text>
              <Button
                onClick={handleAddExpense}
                className={classes.button}
                disabled={!areFieldsValid}
              >
                Agregar
              </Button>
            </Flex>
          </Grid.Col>
          {newExpenses?.length > 0 && (
            <Grid.Col span={12}>
              <Grid columns={5}>
                <Grid.Col span={1}>
                  <Text color="#004236" fw={700}>
                    Gasto
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Text color="#004236" fw={700}>
                    Valor
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Text color="#004236" fw={700}>
                    Proveedor
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Text color="#004236" fw={700}>
                    NIT/Documento
                  </Text>
                </Grid.Col>
                <Grid.Col span={1}>
                  <Text color="#004236" fw={700}>
                    Factura
                  </Text>
                </Grid.Col>
                {newExpenses?.map((item, index) => (
                  <Grid.Col span={5} key={index}>
                    <Grid columns={5}>
                      <Grid.Col span={1}>
                        <Text color="#004236">
                          {item.tipoGasto.split("/")[1]}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text color="#004236">
                          {item.isDollar
                            ? utils.formatterUSD.format(item.valorFinal)
                            : utils.formatter.format(item.valorFinal)}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text color="#004236">{item.nombreProveedor}</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text color="#004236">{item.nitProveedor}</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Flex align="center" gap={8} wrap="wrap">
                          <Text color="#004236">
                            {item.archivoFactura.name}
                          </Text>
                          <ActionIcon
                            data-testid="action-icon"
                            onClick={() => handleDeleteExpense(index)}
                            ml="auto"
                          >
                            <FaTrashCan color="#004236" />
                          </ActionIcon>
                        </Flex>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
          )}
        </Grid>
      </GlobalCard>
    </>
  );
};

export default AddNewExpenses;
