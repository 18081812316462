import { Flex, Checkbox, Grid, TextInput, Select, Button } from "@mantine/core";
import { LuChevronDown } from "react-icons/lu";

const ProviderModal = ({
  opened,
  naturalPerson,
  setNaturalPerson,
  setLegalPerson,
  legalPerson,
  providerForm,
  handleSaveProvider,
  data,
  close,
  ModalComponent,
  classes,
}) => {
  return (
    <ModalComponent opened={opened} onClose={close} title="Datos de proveedor">
      <Flex
        align="center"
        justify="center"
        gap={8}
        py={12}
        sx={(theme) => ({
          [theme.fn.smallerThan("md")]: {
            flexDirection: "column",
          },
        })}
      >
        <Checkbox
          checked={naturalPerson}
          onChange={(e) => {
            setNaturalPerson(e.currentTarget.checked);
            setLegalPerson(!e.currentTarget.checked);
          }}
          label="Persona natural"
          className={classes.checkBox}
        />
        <Checkbox
          checked={legalPerson}
          onChange={(e) => {
            setLegalPerson(e.currentTarget.checked);
            setNaturalPerson(!e.currentTarget.checked);
          }}
          label="Persona jurídica"
          className={classes.checkBox}
        />
      </Flex>
      <form onSubmit={providerForm.onSubmit(handleSaveProvider)}>
        <Grid>
          {legalPerson && (
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...providerForm.getInputProps("nit")}
                label="NIT"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="NIT"
                withAsterisk
              />
            </Grid.Col>
          )}

          {legalPerson && (
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...providerForm.getInputProps("verificationDigit")}
                label="Dígito de verificación"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="Dígito de verificación"
                withAsterisk
              />
            </Grid.Col>
          )}

          {legalPerson && (
            <Grid.Col span={12}>
              <TextInput
                {...providerForm.getInputProps("companyName")}
                label="Razón social"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="Razón social"
                withAsterisk
              />
            </Grid.Col>
          )}

          {naturalPerson && (
            <Grid.Col span={12}>
              <TextInput
                {...providerForm.getInputProps("id")}
                label="Número de identificación"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="Número de identificación"
                withAsterisk
              />
            </Grid.Col>
          )}

          {naturalPerson && (
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...providerForm.getInputProps("firstName")}
                label="Primer Nombre"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="Nombres"
                withAsterisk
              />
            </Grid.Col>
          )}

          {naturalPerson && (
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...providerForm.getInputProps("secondName")}
                label="Segundo nombre"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="Apellidos"
              />
            </Grid.Col>
          )}

          {naturalPerson && (
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...providerForm.getInputProps("lastFirstName")}
                label="Primer apellido"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="Apellidos"
                withAsterisk
              />
            </Grid.Col>
          )}

          {naturalPerson && (
            <Grid.Col xs={12} md={6}>
              <TextInput
                {...providerForm.getInputProps("lastSecondName")}
                label="Segundo apellido"
                classNames={{
                  input: classes.input,
                  label: classes.labelSecondary,
                }}
                placeholder="Apellidos"
                withAsterisk
              />
            </Grid.Col>
          )}

          <Grid.Col span={12}>
            <TextInput
              {...providerForm.getInputProps("address")}
              label="Dirección"
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              placeholder="Dirección"
              withAsterisk
            />
          </Grid.Col>

          <Grid.Col xs={12} md={6}>
            <Select
              {...providerForm.getInputProps("department")}
              label="Departamento"
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
                item: classes.item,
                rightSection: classes.rightSection,
              }}
              searchable
              placeholder="Departamento"
              data={data?.departments}
              rightSection={<LuChevronDown size="1rem" color="#004236" />}
              withAsterisk
            />
          </Grid.Col>

          <Grid.Col xs={12} md={6}>
            <Select
              {...providerForm.getInputProps("city")}
              label="Ciudad"
              classNames={{
                label: classes.labelSecondary,
                input: classes.input,
                item: classes.item,
                rightSection: classes.rightSection,
              }}
              placeholder="Ciudad"
              data={providerForm.isDirty("department") ? data?.cities : []}
              withAsterisk
              rightSection={<LuChevronDown size="1rem" />}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <TextInput
              {...providerForm.getInputProps("phone")}
              label="Teléfono"
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              placeholder="Teléfono"
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <TextInput
              {...providerForm.getInputProps("email")}
              label="Correo electrónico"
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              placeholder="Correo electrónico"
            />
          </Grid.Col>
        </Grid>
        <Button
          className={classes.button}
          fullWidth
          mt={12}
          type="submit"
          disabled={!providerForm.isValid()}
        >
          Guardar
        </Button>
      </form>
    </ModalComponent>
  );
};

export default ProviderModal;
