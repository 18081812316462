import {
  fetchPrelegalizationMulti,
  fetchPrelegalizatinUnion,
  fetchPrelegalizatinRegister,
  parseDateWihtFormat,
} from "@mfe/ts-common-ave-utilitaries";
import { Text } from "@mantine/core";

export const tripPayload = (data) => ({
  idTravel: data?.travelId,
  transactionEmployeeID: data?.travelerId,
  transactionEmployeeName: data?.traveler,
  newFinalComputerID:
    data?.form?.values?.newApprover?.codigoEmpleado ||
    data?.travelInfo?.funcionariosIdAprobador,
  previousApproverID: data?.form?.values?.newPreApprover?.codigoEmpleado,
  modifiedDatesList: [data?.trips?.[0]]?.map((trip, index) => ({
    idDestino: data?.travelInfo?.listaDestinos?.[index]?.idDestino,
    inicialFechaIda: parseDateWihtFormat(trip?.FechaInicio, "YYYY-MM-DD"),
    inicialFechaVuelta: parseDateWihtFormat(trip?.FechaFin, "YYYY-MM-DD"),
    nuevaFechaIda: parseDateWihtFormat(data?.dates?.[index][0], "YYYY-MM-DD"),
    nuevaHoraIda: 0,
    nuevaFechaVuelta: parseDateWihtFormat(
      data?.dates?.[index][1],
      "YYYY-MM-DD"
    ),
    nuevaHoraFin: 0,
  })),
  reportedExpensesList: data?.otherExpenses?.map((expense) => ({
    tipoGasto: expense?.TipoGasto ? expense?.TipoGasto : "REGISTRADO",
    gastoUsado: expense?.GastoUtilizado,
    idGastoReportado: String(expense?.GastoId),
    nombreProveedor: expense?.Proveedor,
    nitProveedor: expense?.NitProveedor,
    valorFinal: expense?.Valor,
    moneda: expense?.TipoMonedaId,
    archivoFactura:
      data?.isEdit && expense?.TipoGasto !== "NUEVO"
        ? expense?.NombFactura?.name?.length > 0
          ? expense?.UlrArchivo
          : expense?.base64File
        : expense?.UlrArchivo,
    extArchivo: data?.isEdit
      ? expense?.NombFactura?.type?.length > 0
        ? expense?.NombFactura?.name?.split(".")?.[1]
        : expense?.NombFactura?.split(".")?.[1]
      : expense?.NombFactura?.name?.split(".")?.[1],
    nombArchivo: data?.isEdit
      ? expense?.NombFactura?.name?.length > 0
        ? expense?.NombFactura?.name?.split(".")?.[0]
        : expense?.NombFactura?.split(".")?.[0]
      : expense?.NombFactura?.name?.split(".")?.[0],
    alias: expense?.alias,
  })),
  justification: data?.form?.values?.justification,
  employeeCode: data?.travelerId,
  travelAllowanceList:
    data?.travelCosts?.[0]
      ?.filter((item) => !item?.disabled)
      ?.map((cost) => ({
        idViatico: cost?.Viaticoid ? String(cost?.Viaticoid) : String(cost?.id),
        valorInicial: cost?.Valor || 0,
        monedaInicial:
          cost?.MonedaTipoId ||
          (data?.travelInfo?.paramTipoViaje === "TVINTERNCL" ? "USD" : "COP"),
        valorFinal: cost?.Valor || 0,
        monedaFinal:
          cost?.MonedaTipoId ||
          (data?.travelInfo?.paramTipoViaje === "TVINTERNCL" ? "USD" : "COP"),
      })) || [],
  bpoServicesList: data?.services?.map((service) => ({
    idServicioBpo: String(service?.ServicioId),
    valor: service?.Valor,
    idApp: service?.AliasServicio,
    tipoMoneda: service?.TipoMonedaId,
  })),
});

export const multiTripPayload = (data) => ({
  idViaje: data?.travelId,
  idFuncionarioTransaccion: data?.travelerId,
  nombreFuncionarioTransaccion: data?.traveler,
  idNuevoOrdenadorFinal: data?.form?.values?.newApprover?.codigoEmpleado,
  idAprobadorPrevio: data?.form?.values?.newPreApprover?.codigoEmpleado,
  listaFechasModificadas: data?.trips?.map((trip, index) => ({
    idDestino: data?.travelInfo?.listaDestinos?.[index]?.idDestino,
    trayectoUsado: data?.usedTrips?.[index],
    inicialFechaIda: parseDateWihtFormat(trip?.FechaInicio, "YYYY-MM-DD"),
    inicialFechaVuelta: parseDateWihtFormat(trip?.FechaFin, "YYYY-MM-DD"),
    nuevaFechaIda: parseDateWihtFormat(data?.dates?.[index][0], "YYYY-MM-DD"),
    nuevaHoraIda: 0,
    nuevaFechaVuelta: parseDateWihtFormat(
      data?.dates?.[index][1],
      "YYYY-MM-DD"
    ),
    nuevaHoraFin: 0,
    viaticosTrayV2:
      data?.travelCosts?.[index]
        ?.filter((item) => !item?.disabled)
        ?.map((cost) => ({
          idViatico: cost?.Viaticoid
            ? String(cost?.Viaticoid)
            : String(cost?.id),
          valorInicial: cost?.Valor || 0,
          monedaInicial:
            cost?.MonedaTipoId ||
            (data?.travelInfo?.paramTipoViaje === "TVINTERNCL" ? "USD" : "COP"),
          valorFinal: cost?.Valor || 0,
          monedaFinal:
            cost?.MonedaTipoId ||
            (data?.travelInfo?.paramTipoViaje === "TVINTERNCL" ? "USD" : "COP"),
        })) || [],
    auxilioMedico: {},
    otrosGastosTrayecto: [],
    listaServiciosBpo: data?.trips?.[index]?.ServicioBpo?.map((service) => ({
      idServicioBpo: String(service?.ServicioId),
      valor: service?.Valor,
      idApp: service?.AliasServicio,
      tipoMoneda: service?.TipoMonedaId,
    })),
  })),
  listaGastosReportados: data?.otherExpenses?.map((expense) => ({
    tipoGasto: expense?.TipoGasto ? expense?.TipoGasto : "REGISTRADO",
    gastoUsado: expense?.GastoUtilizado,
    idGastoReportado: String(expense?.GastoId),
    nombreProveedor: expense?.Proveedor,
    nitProveedor: expense?.NitProveedor,
    valorFinal: expense?.Valor,
    moneda: expense?.TipoMonedaId,
    archivoFactura:
      data?.isEdit && expense?.TipoGasto !== "NUEVO"
        ? expense?.NombFactura?.name?.length > 0
          ? expense?.UlrArchivo
          : expense?.base64File
        : expense?.UlrArchivo,
    extArchivo: data?.isEdit
      ? expense?.NombFactura?.type?.length > 0
        ? expense?.NombFactura?.name?.split(".")?.[1]
        : expense?.NombFactura?.split(".")?.[1]
      : expense?.NombFactura?.name?.split(".")?.[1],
    nombArchivo: data?.isEdit
      ? expense?.NombFactura?.name?.length > 0
        ? expense?.NombFactura?.name?.split(".")?.[0]
        : expense?.NombFactura?.split(".")?.[0]
      : expense?.NombFactura?.name?.split(".")?.[0],
    alias: expense?.alias,
  })),
  justificacion: data?.form?.values?.justification,
  codigoEmpleado: data?.travelerId,
  obsChangeStatus: "",
  VuelosCharter: {},
});

export const aidPayloadTrip = (data) => ({
  idTravel: data?.travelId,
  transactionEmployeeID: data?.travelerId,
  transactionEmployeeName: data?.traveler,
  newFinalComputerID: data?.form?.values?.newApprover?.codigoEmpleado,
  previousApproverID: "",
  justification: data?.form?.values?.justification,
  employeeCode: data?.travelerId,
  obsChangeStatus: "",
  modifiedDatesList: [data?.trips?.[0]]?.map((trip, index) => ({
    idDestino: data?.travelInfo?.listaDestinos?.[index]?.idDestino,
    inicialFechaIda: parseDateWihtFormat(trip?.FechaInicio, "YYYY-MM-DD"),
    nuevaFechaIda: parseDateWihtFormat(
      data?.dataAid?.EsUrgencia && data?.dataAid?.Acompanante
        ? data?.formAid?.acompanionValues?.initial
        : data?.formAid?.beneficiaryValues?.initial,
      "YYYY-MM-DD"
    ),
    inicialFechaVuelta: parseDateWihtFormat(trip?.FechaFin, "YYYY-MM-DD"),
    nuevaFechaVuelta: parseDateWihtFormat(
      data?.dataAid?.EsUrgencia && data?.dataAid?.Acompanante
        ? data?.formAid?.acompanionValues?.end
        : data?.formAid?.beneficiaryValues?.end,
      "YYYY-MM-DD"
    ),
    nuevaHoraIda: 0,
    nuevaHoraFin: 0,
  })),
  modifiedAidDates: {
    perBeneficiario: {
      inicialFechaIda: parseDateWihtFormat(
        data?.dataAid?.PerBeneficiario?.fechaInicioViaje,
        "YYYY-MM-DD"
      ),
      inicialFechaVuelta: parseDateWihtFormat(
        data?.dataAid?.PerBeneficiario?.fechaFinViaje,
        "YYYY-MM-DD"
      ),
      nuevaFechaIda: parseDateWihtFormat(
        data?.formAid?.beneficiaryValues?.initial,
        "YYYY-MM-DD"
      ),
      nuevaFechaVuelta: parseDateWihtFormat(
        data?.formAid?.beneficiaryValues?.end,
        "YYYY-MM-DD"
      ),
      auxilioUtilizado: data?.formAid?.beneficiaryValues?.aidUsed,
      retorno: data?.formAid?.beneficiaryValues?.return,
      nuevaHoraFin: 0,
      nuevaHoraIda: 0,
    },
    perAcompanante: {
      inicialFechaIda: parseDateWihtFormat(
        data?.dataAid?.PerAcompanante?.fechaInicioViaje,
        "YYYY-MM-DD"
      ),
      inicialFechaVuelta: parseDateWihtFormat(
        data?.dataAid?.PerAcompanante?.fechaFinViaje,
        "YYYY-MM-DD"
      ),
      nuevaFechaIda: parseDateWihtFormat(
        data?.formAid?.acompanionValues?.initial,
        "YYYY-MM-DD"
      ),
      nuevaFechaVuelta: parseDateWihtFormat(
        data?.formAid?.acompanionValues?.end,
        "YYYY-MM-DD"
      ),
      auxilioUtilizado: data?.formAid?.acompanionValues?.aidUsed,
      retorno: data?.formAid?.acompanionValues?.return,
      nuevaHoraIda: 0,
      nuevaHoraFin: 0,
    },
  },
  medicalAssistance: {
    monedaInicial: "COP",
    valorInicialAuxilio: data?.dataAid?.ValorTotalAuxilio,
    valorFinalAuxilio: 0,
    origen: 0,
    acompanante: data?.dataAid?.Acompanante,
    calculaAcompanante: data?.formAid?.acompanionValues?.companionliquid,
    perBeneficiario: {
      totalTarifaRetorno: data?.dataAid?.PerAcompanante?.totalTarifaRetorno,
      totalTarifaPernocte: data?.dataAid?.PerAcompanante?.tarifaPagarPernocte,
      totalTarifaPersona: data?.dataAid?.PerAcompanante?.totalTarifaPersona,
      auxilioUtilizado: data?.formAid?.beneficiaryValues?.aidUsed,
    },
    perAcompanante: {
      totalTarifaRetorno: data?.dataAid?.PerAcompanante?.totalTarifaRetorno,
      totalTarifaPernocte: data?.dataAid?.PerAcompanante?.tarifaPagarPernocte,
      totalTarifaPersona: data?.dataAid?.PerAcompanante?.totalTarifaPersona,
      auxilioUtilizado: data?.formAid?.acompanionValues?.aidUsed,
    },
  },
  travelCard: data?.cardTravel,
  reportedExpensesList: data?.otherExpenses?.map((expense) => ({
    tipoGasto: expense?.TipoGasto ? expense?.TipoGasto : "REGISTRADO",
    gastoUsado: expense?.GastoUtilizado,
    idGastoReportado: String(expense?.GastoId),
    nombreProveedor: expense?.Proveedor,
    nitProveedor: expense?.NitProveedor,
    valorFinal: expense?.Valor,
    moneda: expense?.TipoMonedaId,
    archivoFactura:
      data?.isEdit && expense?.TipoGasto !== "NUEVO"
        ? expense?.NombFactura?.name?.length > 0
          ? expense?.UlrArchivo
          : expense?.base64File
        : expense?.UlrArchivo,
    extArchivo: data?.isEdit
      ? expense?.NombFactura?.type?.length > 0
        ? expense?.NombFactura?.name?.split(".")?.[1]
        : expense?.NombFactura?.split(".")?.[1]
      : expense?.NombFactura?.name?.split(".")?.[1],
    nombArchivo: data?.isEdit
      ? expense?.NombFactura?.name?.length > 0
        ? expense?.NombFactura?.name?.split(".")?.[0]
        : expense?.NombFactura?.split(".")?.[0]
      : expense?.NombFactura?.name?.split(".")?.[0],
    alias: expense?.alias,
  })),
  travelAllowanceList:
    data?.travelCosts?.[0]
      ?.filter((item) => !item?.disabled)
      ?.map((cost) => ({
        idViatico: cost?.Viaticoid ? String(cost?.Viaticoid) : String(cost?.id),
        valorInicial: cost?.Valor || 0,
        monedaInicial:
          cost?.MonedaTipoId ||
          (data?.travelInfo?.paramTipoViaje === "TVINTERNCL" ? "USD" : "COP"),
        valorFinal: cost?.Valor || 0,
        monedaFinal:
          cost?.MonedaTipoId ||
          (data?.travelInfo?.paramTipoViaje === "TVINTERNCL" ? "USD" : "COP"),
      })) || [],
  bpoServicesList: data?.services?.map((service) => ({
    idServicioBpo: String(service?.ServicioId),
    valor: service?.Valor,
    idApp: service?.AliasServicio,
    tipoMoneda: service?.TipoMonedaId,
  })),
});

const renderPrelegalizationSuccess = (data) => {
  return (
    <>
      <Text>
        {`La legalización para el viaje ${data?.travelLiquidatorData?.Id} ha sido creada con éxito`}
      </Text>
      <Text>
        Recuerde que la legalización se debe enviar para su aprobación.
      </Text>
    </>
  );
};

async function handleRequest(
  fetchFunction,
  requestItem,
  tokenApi,
  setLoading,
  open,
  setModalContent,
  data
) {
  try {
    setLoading(true);
    await fetchFunction(tokenApi, requestItem);
    setModalContent({
      content: renderPrelegalizationSuccess(data),
      showButtons: true,
      closeOnClickOutside: false,
      onClick: () => {},
    });
    open();
    localStorage.removeItem("isEdit");
  } catch (error) {
    setModalContent({
      content:
        error?.response?.data?.mensaje ||
        (error?.response?.data?.errors &&
          Object.values(error?.response?.data?.errors).join(", ")) ||
        "Hubo un error, inténtelo de nuevo.",
      showButtons: false,
      closeOnClickOutside: true,
      onClick: () => {},
    });
    open();
  } finally {
    setLoading(false);
  }
}

export const handleReliquidate = (
  requestItem,
  tokenApi,
  setLoading,
  open,
  setModalContent,
  data
) =>
  handleRequest(
    fetchPrelegalizatinRegister,
    requestItem,
    tokenApi,
    setLoading,
    open,
    setModalContent,
    data
  );

export const handleReliquidateUnion = (
  requestItem,
  tokenApi,
  setLoading,
  open,
  setModalContent,
  data
) =>
  handleRequest(
    fetchPrelegalizatinUnion,
    requestItem,
    tokenApi,
    setLoading,
    open,
    setModalContent,
    data
  );

export const handleReliquidateMulti = (
  requestItem,
  tokenApi,
  setLoading,
  open,
  setModalContent,
  data
) =>
  handleRequest(
    fetchPrelegalizationMulti,
    requestItem,
    tokenApi,
    setLoading,
    open,
    setModalContent,
    data
  );
