import { useEffect } from "react";
import { Grid, TextInput, Checkbox, Group, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";
import Label from "./label.component";

export const ModifyDatesAid = ({
  form,
  data,
  dataLegalization,
  title,
  dateType,
  isEdit,
  classes,
  GlobalCard,
  es,
}) => {
  const validateForm = dateType === "beneficiario";

  const dataAid = isEdit
    ? dataLegalization?.[0]?.auxilioMedico
    : data?.auxilioMedico;

  useEffect(() => {
    if (dataAid)
      form.setValues({
        beneficiaryValues: {
          initial: moment(dataAid?.PerBeneficiario?.fechaInicioViaje).toDate(),
          end: moment(dataAid?.PerBeneficiario?.fechaFinViaje).toDate(),
          return: dataAid?.PerBeneficiario?.retorno,
          aidUsed: dataAid?.PerBeneficiario?.auxilioUtilizado,
        },
        acompanionValues: {
          initial: moment(dataAid?.PerAcompanante?.fechaInicioViaje).toDate(),
          end: moment(dataAid?.PerAcompanante?.fechaFinViaje).toDate(),
          return: dataAid?.PerAcompanante?.retorno,
          aidUsed: dataAid?.PerAcompanante?.auxilioUtilizado,
          companionliquid: dataAid?.CalculaAcompanante,
        },
      });
  }, [data?.length, dataLegalization?.length]);

  return (
    <>
      <Text color="#004236" size="xl" fw={500}>
        {title}
      </Text>
      <GlobalCard>
        <Grid align="center">
          <Grid.Col xs={12} md={3}>
            <TextInput
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              value={data?.nombreCiudadOrigen}
              label="Ciudad de origen"
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={3}>
            <TextInput
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              value={data?.nombreCiudadDestino}
              label="Ciudad de destino"
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={3}>
            <DatePickerInput
              {...form.getInputProps(
                validateForm
                  ? "beneficiaryValues.initial"
                  : "acompanionValues.initial"
              )}
              label="Modificar fecha inicial"
              locale={es}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
                day: classes.calendar,
              }}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={3}>
            <DatePickerInput
              {...form.getInputProps(
                validateForm ? "beneficiaryValues.end" : "acompanionValues.end"
              )}
              locale={es}
              label={
                <Label
                  title="Modificar fecha final"
                  description="Para activar la modificación de la fecha final, por favor, cambie primero la fecha inicial."
                />
              }
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
                day: classes.calendar,
              }}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group position="center">
              <Checkbox
                {...form.getInputProps(
                  validateForm
                    ? "beneficiaryValues.return"
                    : "acompanionValues.return",
                  { type: "checkbox" }
                )}
                label="Retorno"
                className={classes.checkBox}
              />
              <Checkbox
                {...form.getInputProps(
                  validateForm
                    ? "beneficiaryValues.aidUsed"
                    : "acompanionValues.aidUsed",
                  { type: "checkbox" }
                )}
                label="Auxilio utilizado"
                className={classes.checkBox}
              />
              {!validateForm && (
                <Checkbox
                  {...form.getInputProps("acompanionValues.companionliquid", {
                    type: "checkbox",
                  })}
                  label="Liquida acompañante"
                  className={classes.checkBox}
                />
              )}
            </Group>
          </Grid.Col>
        </Grid>
      </GlobalCard>
    </>
  );
};
